@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0 0;
}

.disclosure-mobile {
  margin-bottom: fn.spacing(6.5);
}

.disclosure-right {
  margin-top: fn.spacing(1);
  text-align: right;
}

.disclosure-left {
  margin-top: fn.spacing(1);
  text-align: left;
}

.disclosure-link,
.disclosure-link:hover {
  color: var.$color-neutral-grey-0;
}

.pd-item,
.pd-item-secondary {
  background: var.$color-neutral-white;
  align-items: center;
  padding: 0 fn.spacing(2);
  justify-content: center;
}

[data-inverse='false'].pd-item-secondary {
  background: var.$color-primary-highlight-blue2;
}

[data-inverse='true'].pd-item-secondary {
  background: var.$color-neutral-white;
}

[data-inverse='true'].pd-item {
  background: var.$color-primary-highlight-blue2;
}

.pd-item-eyebrow {
  text-align: left;
  padding-bottom: 0;
}

.pd-item-list {
  padding: 0;
  margin: 0;
}

.pd-item-panel-left {
  padding: fn.spacing(6) 0 0 0;
  max-width: 500px;
}

.pd-item-panel-right {
  padding: 0 0 fn.spacing(6) 0;
  max-width: 500px;
}

[data-variation='variation1'] .pd-item-panel-left {
  padding: fn.spacing(2) 0;
}

[data-variation='variation1'] .pd-item-panel-right {
  padding: fn.spacing(2) 0;
}

.pd-item-brand,
.pd-item-info {
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
  max-width: 420px;
  margin: 0 auto;
  position: relative;
}

.pd-item-brand {
  gap: 0;
}

.pd-item-image {
  border-radius: fn.spacing(1);
}

.pd-item-card-image {
  position: relative;
  margin-top: -70px;
}

[data-variation='variation1'] .pd-item-card-image {
  padding: 0 fn.spacing(1.25);
  margin-top: fn.spacing(-4.375);
}

.card-link {
  padding: 0 !important;
}

[data-variation='variation1'] .card-link {
  padding-left: 0;

  p {
    width: 100%;
    text-align: left;
  }
}

[data-variation='variation1'].pd-item,
[data-variation='variation1'].pd-item-secondary {
  padding: fn.spacing(4) fn.spacing(2);
}

.pd-item-card-image-disclosure {
  position: relative;
  margin-top: -70px;
}

.pd-item-list-text {
  line-height: 0;
}

.sticky-tabs {
  margin-top: fn.spacing(4);
}

.pd-eye-brow {
  letter-spacing: 0.25em;
  padding-bottom: fn.spacing(1);
}

.pd-item-no-secondary-image .pd-item-panel-left {
  padding: fn.spacing(4) 0;
}

.button {
  align-self: center;
  width: 100%;
}

.app-store-btns-container {
  display: flex;
  justify-content: center;
}

[data-component='GraphicCardList'] .pd-item-subimage-html {
  min-width: 250px;
  margin: 30px;
}

[data-component='GraphicCommentBubble'] .pd-item-subimage-html {
  min-width: 250px;
}

.pd-help-text {
  color: var.$color-neutral-gray-eighty;
  margin-top: fn.spacing(2);
}

@media screen and (min-width: var.$breakpoint-lg) {
  .button {
    width: fit-content;
    align-self: start;
  }

  .section {
    padding: fn.spacing(8) 0 0 0;
  }

  .pd-item,
  .pd-item-secondary {
    padding: fn.spacing(8) 0 0 0;
    justify-content: inherit;
    min-height: fn.spacing(50);
  }

  .pd-item-no-secondary-image {
    padding: fn.spacing(8) 0;
  }

  .pd-item-card-image {
    margin-top: -200px;
    width: 130%;
    right: -70px;
  }

  .pd-item-card-image-disclosure {
    margin-top: -224px;
    width: 130%;
    right: -70px;
  }

  .pd-item-secondary .pd-item-card-image {
    left: -170px;
  }

  .pd-item-secondary .pd-item-card-image-disclosure {
    left: -170px;
  }

  .pd-item-panel-left,
  .pd-item-panel-right {
    padding: 0;
    max-width: initial;
  }

  .pd-item-panel-left {
    .pd-item-brand {
      padding-right: 50px;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  .pd-eye-brow {
    padding-bottom: fn.spacing(2);
  }

  [data-variation='variation1'] .pd-item-card-image {
    margin-top: fn.spacing(-9);
    width: 100%;
    right: fn.spacing(-20.625);
  }

  [data-variation='variation1'].pd-item-secondary .pd-item-card-image {
    left: fn.spacing(-19);
  }

  .pd-item[data-variation='variation1'],
  .pd-item-secondary[data-variation='variation1'] {
    padding: fn.spacing(8) 0;
  }

  .app-store-btns-container {
    display: flex;
    justify-content: flex-start;
  }

  [data-component='GraphicCardList'] .pd-item-subimage-html {
    min-width: 350px;
    width: 350px;
    margin: 55px;
  }

  [data-component='GraphicCommentBubble'] .pd-item-subimage-html {
    min-width: 300px;
    width: 300px;
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }

  [data-component='GraphicCommentBubble'] .pd-item-subimage-html {
    min-width: 350px;
    width: 350px;
  }
}

.backgroud-color-primary-highlight-blue2 {
  background: var.$color-primary-highlight-blue2;
}

.backgroud-color-neutral-background-default {
  background: var.$color-neutral-background-default;
}
